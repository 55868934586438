import { getRoleBasedRedirect, useUserStore } from '~/stores/user'
import { UserRole, type IUser } from '~/types/models/user.interface'

export default defineNuxtRouteMiddleware(async () => {
	const store = useUserStore()
	const user = await useApi('/auth/me')

	if (user) {
		store.login(user as IUser)
		const redirectUrl = getRoleBasedRedirect(user.role)
		return navigateTo(redirectUrl)
	}

	store.logout()
})
